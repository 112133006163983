import {ordersV3_ordersV3_orders as orderType} from 'autogen/ordersV3';

import GTMEvent from '../enums/gtm-event';
import * as checkoutTypeEnum from '../enums/new-checkout-types';
import {isServerSide} from '../lib/env';
import {checkoutTypeExistsAndContains} from '../utils/checkout-types';
import {getPerUnitOrMultiplePriceString} from '../utils/view-helpers/calculation-view-helpers';
import {env} from '../utils/environment';
import {GetCouponsV4ForCartItemUpcs_couponsV4} from '../../autogen/GetCouponsV4ForCartItemUpcs';

import {getCitrusInstance} from './citrus-service';
import {IAddToCartDataLayer, IRemoveFromCartDataLayer} from './types';

const NOOP = () => 'noop';

const AISLES_ONLINE_GA_CODE = 'AOL';
const MTO_GA_CODE = 'MTO';

interface ICustomerData {
    customerUuid?: string;
    fuelSaverCardNumber?: number;
    isAuthenticated?: boolean;
}

declare global {
    interface Window {
        dataLayer: any[];
    }
}

const clickHandlersBySource = {
    citrus: async (id) => {
        const citrus = await getCitrusInstance();

        citrus.reportClick(id);
    }
};

const impressionHandlersBySource = {
    citrus: async (id) => {
        const citrus = await getCitrusInstance();

        citrus.reportImpression(id);
    }
};

const productsToReport = (cartItems) =>
    cartItems
        ? cartItems.map((cartItem) => {
              return {
                  brand: cartItem.storeProduct.product.brandName,
                  id: cartItem.productId,
                  name: cartItem.storeProduct.product.name,
                  price: cartItem.storeProduct.price,
                  quantity: cartItem.quantity
              };
          })
        : null;

const getHandler = (handlersBySource, productResultSource) => handlersBySource[productResultSource] || NOOP;

const reportForSources = async (sources, handlersBySource) =>
    Promise.all(sources.map(({key, sourceId}) => getHandler(handlersBySource, key)(sourceId)));

export const reportImpression = async ({sources}) => reportForSources(sources, impressionHandlersBySource);

export const reportClick = async ({sources}) => reportForSources(sources, clickHandlersBySource);

/**
 * Waits for GTM datalayer to be available
 *
 * @param {Function} callback
 */
export const waitForDataLayer = (callback) => {
    if (isServerSide()) {
        return;
    }

    const dataLayerCheck = () =>
        Boolean(window.dataLayer) || Boolean(window.parentIFrame && window.parent && window.parent.window.dataLayer);

    if (dataLayerCheck()) {
        callback();

        return;
    }

    const interval = setInterval(() => {
        if (dataLayerCheck()) {
            clearInterval(interval);

            callback();
        }
    }, 200);
};

export const reportListEvent = (action) =>
    waitForDataLayer(() => {
        let windowDataLayer = window.dataLayer;

        try {
            if (window.parentIFrame) {
                windowDataLayer = window.parent.window.dataLayer;
            }
        } catch (error) {}

        windowDataLayer.push({
            event: GTMEvent.GAEvent,
            eventAction: action,
            eventCategory: 'List Interaction',
            eventLabel: 'Aisles Online'
        });
    });

export const reportPromoCodeEvent = (promo, wasSuccessful) =>
    waitForDataLayer(() => {
        window.dataLayer.push({
            event: GTMEvent.GAEvent,
            eventAction: `Promo Code Applied - ${wasSuccessful ? 'Successful' : 'Unsuccessful'}`,
            eventCategory: 'Checkout Interaction',
            eventLabel: promo
        });
    });

export const reportPageView = (path) =>
    waitForDataLayer(() => {
        window.dataLayer.push({
            event: GTMEvent.IframePageView,
            iframePath: path
        });
    });

export const reportListProductClick = (listItemIndex: number, name: string, productId: number | string | null) =>
    waitForDataLayer(() => {
        window.dataLayer.push({
            ecommerce: {
                click: {
                    actionField: {
                        list: 'Shopping List'
                    },
                    products: [
                        {
                            id: productId,
                            name,
                            position: listItemIndex + 1
                        }
                    ]
                }
            },
            event: GTMEvent.ProductClick
        });
    });

export const reportCartFlyoutScroll = () =>
    waitForDataLayer(() => {
        window.dataLayer.push({
            event: GTMEvent.CartFlyoutScroll
        });
    });

export const addToCartDataLayer = ({product, quantity, swimlaneType, coupon, position}: IAddToCartDataLayer) =>
    waitForDataLayer(() => {
        if (!product) {
            return;
        }

        const price = getPerUnitOrMultiplePriceString({
            ...product,
            ...product.storeProduct
        });

        const breadcrumbs =
            product.breadcrumbs && product.breadcrumbs.length
                ? product.breadcrumbs.map((b) => b.breadcrumbName).join(' > ')
                : '';
        const category = swimlaneType && swimlaneType.length ? swimlaneType : breadcrumbs;

        window.dataLayer.push({
            ecommerce: {
                add: {
                    products: [
                        {
                            item_brand: product.brandName || '',
                            item_category: category,
                            item_id: product.productId,
                            item_name: product.name,
                            price: price ? Number(`${price}`.replace('$', '')) : 0,
                            quantity,
                            ...(coupon && {
                                coupon: coupon.couponId,
                                ...(product?.storeProduct?.basePrice && {
                                    discount: product?.storeProduct?.basePrice - product?.storeProduct?.price
                                })
                            }),
                            ...(swimlaneType &&
                                swimlaneType.length && {
                                    item_list_name: swimlaneType || ''
                                }),
                            ...(position && {
                                product_position: position
                            })
                        }
                    ]
                }
            },
            event: 'addToCart',
            gaEventAction: `${window.location.pathname}`,
            gaEventCategory: 'Ecommerce',
            gaEventLabel: `Adding to cart from ${window.location.pathname}${swimlaneType ? ` (${swimlaneType})` : ''}`
        });
    });

export const removeFromCartDataLayer = ({
    product,
    quantity,
    swimlaneType,
    coupon,
    position
}: IRemoveFromCartDataLayer) =>
    waitForDataLayer(() => {
        let productData: any[] | null = [];

        if (product) {
            const breadcrumbs =
                product.breadcrumbs && product.breadcrumbs.length
                    ? product.breadcrumbs.map((b) => b.breadcrumbName).join(' > ')
                    : '';
            const category = swimlaneType && swimlaneType.length ? swimlaneType : breadcrumbs;

            productData = [
                {
                    item_brand: product.brandName || '',
                    item_category: category,
                    item_id: product.productId,
                    item_name: product.name,
                    price: product?.price ? `${product.price}`.replace('$', '') : null,
                    quantity,
                    ...(coupon && {
                        coupon: coupon.couponId,
                        ...(product?.storeProduct?.basePrice && {
                            discount: product?.storeProduct?.basePrice - product?.storeProduct?.price
                        })
                    }),
                    ...(swimlaneType &&
                        swimlaneType.length && {
                            item_list_name: swimlaneType || ''
                        }),
                    ...(position && {
                        product_position: position
                    })
                }
            ];
        }

        window.dataLayer.push({
            ecommerce: {
                remove: {
                    products: productData
                }
            },
            event: 'removeFromCart',
            gaEventAction: `${window.location.pathname}`,
            gaEventCategory: 'Ecommerce',
            gaEventLabel: `Removing from cart from ${window.location.pathname}${
                swimlaneType ? ` (${swimlaneType})` : ''
            }`
        });
    });

export const reportProductImpression = (product, swimLaneType, index) =>
    waitForDataLayer(() => {
        if (!product) {
            return;
        }

        const price = getPerUnitOrMultiplePriceString({
            ...product,
            ...product.storeProduct
        });

        window.dataLayer.push({
            ecommerce: {
                detail: {
                    products: [
                        {
                            brand: product.brandName,
                            category: product.category,
                            id: product.productId,
                            name: product.name,
                            price,
                            quantity: 0
                        }
                    ]
                }
            },
            event: GTMEvent.ProductImpressions,
            gaEventAction: `${window.location.pathname}`,
            gaEventCategory: 'Ecommerce',
            gaEventLabel: `Viewing ${product.name} from ${window.location.pathname}`
        });

        const data = {
            ecommerce: {
                item_list_name: swimLaneType,
                items: [
                    {
                        coupon: product.coupon,
                        discount: product.discount,
                        index: 0,
                        item_brand: product.brandName,
                        item_category: product.category,
                        item_id: product.productId,
                        item_list_name: swimLaneType,
                        item_name: product.name,
                        price,
                        product_position: index + 1
                    }
                ]
            },
            event: 'view_item_list'
        };

        window.dataLayer.push(data);
    });

export const reportProductDetailsPageImpression = (
    product: any,
    storeProduct: any,
    breadcrumbData: {breadcrumbName: string}[] = []
) =>
    waitForDataLayer(() => {
        const formattedCategory = breadcrumbData.reduce((acc, curr) => {
            if (!acc) {
                return curr.breadcrumbName;
            }

            return `${acc} > ${curr.breadcrumbName}`;
        }, '');

        const price = getPerUnitOrMultiplePriceString({
            ...product,
            ...storeProduct
        });

        window.dataLayer.push({
            ecommerce: {
                detail: {
                    products: [
                        {
                            brand: product.brandName,
                            category: formattedCategory,
                            id: product.productId,
                            name: product.name,
                            price
                        }
                    ]
                }
            },
            event: GTMEvent.ProductImpressions,
            gaEventAction: `${window.location.pathname}`,
            gaEventCategory: 'Ecommerce',
            gaEventLabel: `Viewing ${product.name} from ${window.location.pathname}`
        });
    });

export const reportProductClick = (product, swimLaneType, index) =>
    waitForDataLayer(() => {
        if (!product) {
            return;
        }

        const price = getPerUnitOrMultiplePriceString({
            ...product,
            ...product.storeProduct
        });

        window.dataLayer.push({
            ecommerce: {
                click: {
                    products: [
                        {
                            brand: product.brandName,
                            category: product.category,
                            id: product.productId,
                            name: product.name,
                            price,
                            quantity: 0
                        }
                    ]
                }
            },
            event: 'productClick',
            gaEventAction: `${window.location.pathname}`,
            gaEventCategory: 'Ecommerce',
            gaEventLabel: `Clicking ${product.name} from ${window.location.pathname}`
        });

        const data = {
            ecommerce: {
                item_list_name: swimLaneType,
                items: [
                    {
                        coupon: product.coupon,
                        discount: product.discount,
                        index: 0,
                        item_brand: product.brandName,
                        item_category: product.category,
                        item_id: product.productId,
                        item_list_name: swimLaneType,
                        item_name: product.name,
                        price,
                        product_position: index + 1
                    }
                ]
            },
            event: 'select_item'
        };

        window.dataLayer.push(data);
    });

export const reportPromoClick = (promotions) =>
    waitForDataLayer(() => {
        window.dataLayer.push({
            ecommerce: {
                promoClick: {
                    promotions: [...promotions]
                }
            },
            event: GTMEvent.PromoClick
        });
    });

export const reportSwimlaneProductImpression = ({index, products, swimlaneTestId}) =>
    waitForDataLayer(() => {
        if (!products || products.length === 0) {
            return;
        }

        const product = products[index];
        const swimlaneProductImpressionsEvent = window.dataLayer.filter(
            (dataLayerEvent) =>
                dataLayerEvent.event === GTMEvent.ProductImpressions && dataLayerEvent.ecommerce.impressions
        );
        const impressions = swimlaneProductImpressionsEvent.reduce(
            (acc, curr) => acc.concat(curr.ecommerce.impressions),
            []
        );
        const hasProductBeenReported = impressions.some((impression) => impression.id === product.productId);

        if (!hasProductBeenReported) {
            window.dataLayer.push({
                ecommerce: {
                    impressions: [
                        {
                            id: product.productId,
                            list: swimlaneTestId,
                            name: product.name,
                            position: index + 1
                        }
                    ]
                },
                event: GTMEvent.ProductImpressions
            });
        }
    });

export const reportAddToCartFromList = (product) =>
    waitForDataLayer(() => {
        window.dataLayer.push({
            ecommerce: {
                add: {
                    products: [
                        {
                            id: product.productId,
                            name: product.name,
                            quantity: product.quantity
                        }
                    ]
                }
            },
            event: GTMEvent.AddToCart,
            gaEventAction: `${window.location.pathname}`,
            gaEventCategory: 'Ecommerce',
            gaEventLabel: `Adding to cart from ${window.location.pathname}`
        });
    });

export const reportBuyAgainProductImpression = (frequentPurchases) =>
    waitForDataLayer(() => {
        window.dataLayer.push({
            ecommerce: {
                impressions: frequentPurchases
                    .filter((purchase) => purchase !== null)
                    .map((frequentPurchase, index) => {
                        return {
                            list: 'AO Shopping Lists / Buy Again',
                            name: frequentPurchase.name,
                            position: index + 1,
                            productId: frequentPurchase.productId
                        };
                    })
            },
            event: GTMEvent.ProductImpressions
        });
    });

export const reportCheckoutStep = ({option, cartItems, step}: {option?: unknown; cartItems?: unknown; step: number}) =>
    waitForDataLayer(() => {
        window.dataLayer.push({
            ecommerce: {
                checkout: {
                    actionField: {
                        option,
                        step
                    },
                    products: productsToReport(cartItems)
                }
            },
            event: GTMEvent.TrackCheckoutStep
        });
    });

export const reportEcomPurchase = (
    order: Pick<
        orderType,
        'estimatedTax' | 'orderId' | 'orderItems' | 'serviceFee' | 'serviceFee' | 'totalWithPromotions'
    >,
    coupons: Pick<GetCouponsV4ForCartItemUpcs_couponsV4, 'brand' | 'upcs' | 'value'>[],
    customerData: ICustomerData
): void => {
    const products =
        order.orderItems?.map((orderItem) => {
            const findRelatedCoupon = (orderItemUpc: string) =>
                coupons.find((coupon) => {
                    if (!coupon.upcs) {
                        return undefined;
                    }

                    return coupon.upcs.some((upc) => (upc ? `${upc.slice(1, 13)}0` === `${orderItemUpc}` : false));
                });

            if (orderItem.storeItem?.__typename === 'RetailSpecificItem') {
                const relatedCoupon = findRelatedCoupon(orderItem.storeItem.upcHyVee);

                return {
                    brand: 'Hy-Vee Retail Specific Item',
                    coupon: relatedCoupon ? `${relatedCoupon.brand}-${relatedCoupon.value}` : '',
                    id: orderItem.productId,
                    name: orderItem.storeItem.description,
                    price: orderItem.storeItem.ecommerceTagPrice,
                    quantity: orderItem.expectedQuantity.value
                };
            }

            const relatedCoupon = findRelatedCoupon(orderItem.storeItem?.item?.upcHyVee ?? '');

            return {
                brand: orderItem.storeItem?.item?.brandFamilyName,
                coupon: relatedCoupon ? `${relatedCoupon.brand}-${relatedCoupon.value}` : '',
                id: orderItem.productId,
                name: orderItem.storeItem?.item?.description,
                price: orderItem.storeItem?.ecommerceTagPrice,
                quantity: orderItem.expectedQuantity.value
            };
        }) ?? [];

    waitForDataLayer(() => {
        window.dataLayer.push({
            account_login_status: customerData.isAuthenticated ? 'logged in' : 'logged out',
            card_id: customerData?.fuelSaverCardNumber || undefined,
            ecommerce: {
                purchase: {
                    actionField: {
                        affiliation: 'Online Store',
                        id: order.orderId,
                        revenue: order.totalWithPromotions,
                        shipping: order.serviceFee,
                        tax: order.estimatedTax
                    },
                    products
                }
            },
            event: '2023purchaseFix',
            uuid: customerData?.customerUuid || undefined
        });
    });
};

export const reportTrackPurchase = ({id, revenue, useStoreTracking}) =>
    waitForDataLayer(() => {
        window.dataLayer.push({
            ecommerce: {
                purchase: {
                    actionField: {
                        id,
                        revenue
                    }
                }
            },
            event: useStoreTracking ? GTMEvent.TrackPurchase : GTMEvent.TrackPurchaseNoStore
        });
    });

export const setCheckoutModeDimension = (checkoutMode, checkoutTypes) => {
    let checkoutDimensionCode = AISLES_ONLINE_GA_CODE;

    if (checkoutMode === 2) {
        checkoutDimensionCode = MTO_GA_CODE;
    } else if (checkoutMode === 3) {
        checkoutDimensionCode = `${AISLES_ONLINE_GA_CODE}-${MTO_GA_CODE}`;
    }

    if (
        checkoutTypeExistsAndContains(checkoutTypes, checkoutTypeEnum.GROCERY) &&
        checkoutTypeExistsAndContains(checkoutTypes, checkoutTypeEnum.MADE_TO_ORDER)
    ) {
        checkoutDimensionCode = `${AISLES_ONLINE_GA_CODE}-${MTO_GA_CODE}`;
    } else if (checkoutTypeExistsAndContains(checkoutTypes, checkoutTypeEnum.GROCERY)) {
        checkoutDimensionCode = AISLES_ONLINE_GA_CODE;
    } else if (checkoutTypeExistsAndContains(checkoutTypes, checkoutTypeEnum.MADE_TO_ORDER)) {
        checkoutDimensionCode = MTO_GA_CODE;
    }

    waitForDataLayer(() => {
        window.dataLayer.push('set', 'cd10', checkoutDimensionCode);
    });
};

export const reportMedalliaInfo = (
    cartSize,
    fulfillmentType,
    languageCode,
    storeId,
    setSentCartSize,
    sentCartSize,
    userId
) => {
    if (sentCartSize !== cartSize) {
        waitForDataLayer(() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            window.dataLayer.medallia = [];

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            window.dataLayer.medallia.push({
                cartSize,
                countryCode: 'US',
                fulfillmentType,
                languageCode,
                storeId,
                userId
            });
        });

        setSentCartSize(cartSize);
    }
};

const getFirstPathSegment = (url: string) => {
    try {
        const pathname = new URL(url)?.pathname;
        const segments = pathname.split('/').filter((segment) => segment.length > 0);

        return segments.length ? segments[0] : 'home page';
    } catch (error) {
        console.error('Error parsing URL:', error);

        return 'home page';
    }
};

export const sharedEvolyticsFields = {
    environment: env(),
    page_type: 'ecommerce',
    promo_percent_viewed: '1',
    site_name: 'Hy-Vee'
};

const additionalFields = (ad, eventName) => {
    if (eventName !== GTMEvent.AdPageLoad)
        return {
            ad_resolution: ad.adResolution || undefined,
            element_position_on_page: Math.round(ad.elementPositionOnPage || 0) || undefined
        };
};

export const getSharedEvolyticsFields = (cardId?: number) => ({
    ...sharedEvolyticsFields,
    card_id: cardId?.toString() ?? '',
    site_subcategory: getFirstPathSegment(window.location.href)
});

export const reportSwimlanePromoCardEvolyticsClick = (promo, customer) => {
    if (promo) {
        const isAuthenticated = Object.keys(customer).length > 0;

        waitForDataLayer(() => {
            window.dataLayer.push({
                account_login_status: `logged ${isAuthenticated ? 'in' : 'out'}`,
                event: GTMEvent.EvolyticsClick,
                promo_action: 'clicked',
                promo_category: 'swimlane',
                promo_destination: promo?.linkUrl,
                promo_name: promo?.image?.alt,
                ...getSharedEvolyticsFields(customer?.fuelSaverCard?.fuelSaverCardNumber)
            });
        });
    }
};

export const reportSwimlanePromoCardEvolyticsImpression = (promo, customer) => {
    if (promo) {
        const isAuthenticated = Object.keys(customer).length > 0;

        waitForDataLayer(() => {
            window.dataLayer.push({
                account_login_status: `logged ${isAuthenticated ? 'in' : 'out'}`,
                event: GTMEvent.EvolyticsImpression,
                promo_action: 'viewed',
                promo_category: 'swimlane',
                promo_destination: promo?.linkUrl,
                promo_name: promo?.image?.alt,
                ...getSharedEvolyticsFields(customer?.fuelSaverCard?.fuelSaverCardNumber)
            });
        });
    }
};

export const reportEvolyticsImpression = (ad, customer, eventName) => {
    if (!ad) return;
    const isAuthenticated = Object.keys(customer).length > 0;

    waitForDataLayer(() => {
        window.dataLayer.push({
            account_login_status: `logged ${isAuthenticated ? 'in' : 'out'}`,
            alt_text: ad.altText || undefined,
            card_id: customer?.fuelSaverCard?.fuelSaverCardNumber.toString() || undefined,
            customer_id: customer?.customerId || undefined,
            event: eventName,
            image_title: ad?.name || undefined,
            page_length: Math.round(ad.pageLength || 0) || undefined,
            promo_action: eventName === GTMEvent.EvolyticsClick ? GTMEvent.Clicked : GTMEvent.Viewed,
            promo_category: ad?.category || ad?.title || undefined,
            promo_destination: ad?.destination || undefined,
            promo_name: ad?.name || ad?.title || undefined,
            site_subcategory: getFirstPathSegment(window.location.href),
            tactic_id: ad?.tacticId,
            uuid: customer?.customerUuid,
            ...sharedEvolyticsFields,
            ...additionalFields(ad, eventName)
        });
    });
};
