export const DISPLAY_CLPE_PROMOTIONS = 'display-clpe-promotions';
export const SEARCH_FEEDBACK = 'aisles-online-search-feedback';
export const DISPLAY_CLPE_SAVINGS = 'cj-display-clpe-savings';
export const NEW_CHECKOUT_VALIDATIONS = 'cj-new-validations';
export const DEAL_DETAILS_REDIRECT = 'aom-deals-detail-redirect';
export const CJ_NEW_CART = 'cj-new-cart';
export const ADD_ORDER_ITEMS = 'fulfillment-add-order-items';
export const CJ_TAX_EXEMPTION = 'cj-tax-exemption';
export const CJ_PRODUCT_BANNER = 'cj-product-banner';
export const PROMO_CARD_SIZING = 'aom-promo-card-sizing';
export const CJ_FULL_REACT_PAYMENTS_PAGE = 'cj-full-react-payments-page';
export const UNIVERSAL_SEARCH_CONTENT_SWIMLANE = 'aoh-search-content-swimlanes';
export const SIDEBAR_CHECKBOXES = 'ao-checkout-sidebar-checkboxes';
export const AOH_IN_STORE_ONLY = 'aoh-web-in-store-only';
export const AOH_IN_STORE_ONLY_POPUP = 'aoh-web-in-store-only-popup';
export const PRICING_TRANSPARENCY = 'pricing-transparency';
export const MO_FILTER_PANEL_BANNER = 'mo-filter-panel-banner';
export const MO_CHECKOUT_BANNER = 'mo-checkout-banner';
export const MO_PRODUCT_DETAILS_BANNER = 'mo-product-details-banner';
export const INCREASE_SUBSTITUTION_PROFIT = 'increase-substitutions-profit';
export const RESERVATION_DRAWER = 'ecom-web-reservation-drawer';
export const EWIC_STORES = 'aisles-online-ewic-stores';
export const EWIC_DEBUG_DATA = 'aisles-online-ewic-debug-data';
export const CROSS_SELL_ON_SEARCH = 'ecom-cross-sell-on-search';
export const ADFLOW_ORDER_CONFIRMATION = 'adflow-checkout-confirmation-toggle';
export const HYFISH_WEB_SHARED_LIST = 'hyfish.web.sharedlist';
export const ADDRESS_VERIFICATION = 'ecom-address-verification-drawer';
export const ECOM_GEO_LOCATION_WEB = 'ecom-geo-location-web';
export const PRODUCT_DETAILS_GEOLOCATION = 'ecom-geo-location-web-pdp';
export const PERKS_PRICING = 'ecom-perks-pricing-web';
export const SHIP_TO_HOME_ADD_TO_CART_PAGE = 'Ecom_ShipToHome_AddToCartPage_Web';
export const PAYMENTS_PAYPAL = 'feature-toggle-payments-paypal';
export const ENHANCED_SEARCH = 'ecomm-enhanced-search-web';
export const SEASONS_SEARCH_IDEAS = 'ecomm-ideas-enhanced-search-web';
export const FULL_BLEED_IMAGES = 'af-4262-product-tile-full-bleed-images';
